import { ReactNode } from "react"

type FeatureProps = {
  icon: ReactNode
  text: string
}

const Feature = ({ icon, text }: FeatureProps) => {

  return (
    <li className="bg-background-60 p-2 backdrop-blur-md bg-opacity-20 rounded-xl flex flex-row h-auto">
      <p className="text-5xl p-2 my-auto">
        {icon}
      </p>
      <p className="text-xl md:text-base xl:text-lg text-left text-neutral-99 [word-spacing:unset] small:[word-spacing:999rem] ml-2 small:ml-0 my-auto">
        {text}
      </p>
    </li>
  )
}

export default Feature