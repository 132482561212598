import ArrowRight from "@modules/common/icons/arrow-right"
import clsx from "clsx"
import Link from "next/link"

type UnderlineLinkProps = {
  href: string
  children?: React.ReactNode
  contrast?: boolean
}

const UnderlineLink = ({ href, children, contrast }: UnderlineLinkProps) => {
  return (
    <div className="flex items-start">
      <Link
        href={href}
        className={clsx("flex items-center text-large-regular border-b border-current gap-x-4 py-2 transition-all duration-300 group hover:pl-4 hover:pr-1", {
          "dark:text-neutral-100": !contrast,
          "text-neutral-60": contrast,
        })}
      >
        <span>{children}</span>
        <ArrowRight
          size={20}
          className="transition-all group-hover:ml-2 duration-300"
        />
      </Link>
    </div >
  )
}

export default UnderlineLink
