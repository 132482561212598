import UnderlineLink from "@modules/common/components/underline-link"
import Image from "next/image"
import Link from "next/link"
import { BsChevronRight } from "react-icons/bs"

const Hero = () => {
  return (
    <div className="h-[80vh] w-full relative bg-white">
      <div className="text-neutral-10 absolute inset-0 z-10 flex flex-col justify-end items-center text-center small:text-right small:items-end small:p-8">
        <h1 className="text-2xl-semi mb-4 drop-shadow-md shadow-black text-neutral-10">
          Tenemos los ultimos productos de gama alta del mercado.
        </h1>
        <p className="text-base-regular max-w-[32rem] mb-6 drop-shadow-md shadow-black text-neutral-10">
          Al mejor precio y con la mejor garantía.
          Equipos 100% Originales, nuevos y sellados.
          Pago contra entrega en bogotá.
        </p>
        <UnderlineLink href="/store" contrast>Ver Productos </UnderlineLink>
        <Image
          src='/iphone-h.png'
          alt='iphone'
          loading="eager"
          priority={true}
          quality={90}
          width={2600}
          height={395}
          layout='responsive'
          style={{
            width: 'auto',
            maxHeight: '70vw',
            padding: '4rem 2rem',
          }}
        />
      </div>
      <div className="z-10 w-auto min-w-full h-screen min-h-full max-h-[80vh] object-cover bg-slate-200"
      >
      </div>
    </div>
  )
}

export default Hero
