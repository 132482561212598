import { useFeaturedProductsQuery } from "@lib/hooks/use-layout-data"
import UnderlineLink from "@modules/common/components/underline-link"
import ProductPreview from "@modules/products/components/product-preview"
import SkeletonProductPreview from "@modules/skeletons/components/skeleton-product-preview"
import {
  MdDeliveryDining,
  MdLockOutline,
  MdOutlineShield,
  MdAttachMoney,
  MdOutlineWallet,
} from "react-icons/md"
import Feature from "./feature"

const FeaturedProducts = () => {
  const { data } = useFeaturedProductsQuery()

  return (
    <div className="py-12">
      <div className="content-container">
        <div className="justify-around pb-7">
          <ul className="grid grid-cols-1 grid-rows-5 small:grid-cols-5 small:grid-rows-1 gap-x-6 gap-y-7">
            <Feature icon={<MdDeliveryDining />} text="Envíos Nacionales" />
            <Feature icon={<MdOutlineShield />} text="Garantía Incluida" />
            <Feature icon={<MdLockOutline />} text="Compra Segura" />
            <Feature icon={<MdAttachMoney />} text="Pagos Contraentrega" />
            <Feature icon={<MdOutlineWallet />} text="Pagos Online" />
          </ul>
        </div>
        <div className="flex flex-col items-center text-center mb-16 pb-10 py-10">
          <span className="text-base-regular dark:text-neutral-90 mb-6">
            Novedades de productos
          </span>
          <p className="text-2xl-regular dark:text-neutral-90 max-w-lg mb-4">
            Los mejores productos a los mejores precios.
          </p>
          <UnderlineLink href="/store">Explora nuestros productos</UnderlineLink>
        </div>
        <ul className="grid grid-cols-2 small:grid-cols-4 gap-x-4 gap-y-8">
          {data
            ? data.map((product) => (
              <li key={product.id}>
                <ProductPreview {...product} />
              </li>
            ))
            : Array.from(Array(4).keys()).map((i) => (
              <li key={i}>
                <SkeletonProductPreview />
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default FeaturedProducts
