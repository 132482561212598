import Footer from "@modules/layout/templates/footer"
import Nav from "@modules/layout/templates/nav"
import React from "react"

const Layout: React.FC = ({ children }) => {
  return (
    <div className="dark flex flex-col h-screen">
      <Nav />
      <main className="relative dark:bg-background-0 dark:text-sky-100 grow">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
